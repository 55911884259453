"use client";
import React, { useState } from "react";
import { FaCode, FaMicrochip, FaTools, FaProjectDiagram } from "react-icons/fa";
import styles from "./ProjectsSection.module.css";

// Project Data grouped by category and sorted newest to oldest
const projectData = [
  {
    category: "Programming",
    icon: <FaCode />,
    projects: [
      {
        title: "AI CCTV Detector",
        date: "Oct 2024 - Nov 2024",
        description:
          "Created software to load live CCTV camera feeds and apply AI models for object detection, anomaly detection, and real-time analytics.",
        skills: "Python, AI, Computer Vision",
      },
      {
        title: "AI Trading Bot",
        date: "May 2024",
        description:
          "Designed an AI-driven trading bot capable of making real-time trade decisions based on market trends and predictive analytics.",
        skills: "Python, AI, Machine Learning, Algorithm Development",
      },
      {
        title: "Process Automation",
        date: "Mar 2023 - Sep 2024",
        description:
          "Developed an automated system to streamline and optimize repetitive company workflows, reducing manual effort and increasing efficiency.",
        skills: "Python, Automation, Process Optimization",
      },
      {
        title: "Grafana Monitoring Systems",
        date: "Jun 2024 - Sep 2024",
        description:
          "Built multiple Grafana monitoring dashboards to track key performance metrics for company systems.",
        skills: "Python, Prometheus, Grafana",
      },
      {
        title: "Dataset Creation & Cleaning Scripts",
        date: "Sep 2023 - Feb 2024",
        description:
          "Developed scripts to automate dataset creation and cleaning for machine learning applications.",
        skills: "Python, JavaScript, Data Processing",
      },
      {
        title: "Image Clustering & Sorting",
        date: "Sep 2023 - Dec 2023",
        description:
          "Created a visualization and clustering tool to categorize and sort large image datasets based on AI-driven similarity analysis.",
        skills: "Python, AI, Data Visualization",
      },
      {
        title: "Algorithm-Based Trading Bot",
        date: "Apr 2022 - Jun 2022",
        description:
          "Developed a trading bot for Stocks, Crypto, and CFDs using Python and PineScript. Implemented algorithmic strategies to automate trade execution and optimize profit margins.",
        skills: "Python, Pinescript, Data Analysis, Machine Learning",
      },
      {
        title: "Discounted.gg",
        date: "Mar 2022 - Dec 2022",
        description:
          "Co-developed a website that scrapes and aggregates discounted games from major online stores, categorizing and displaying them efficiently.",
        skills: "TypeScript, Vue.js, Web Scraping, Data Aggregation",
      },
      {
        title: "Chess Game (A-Level Project)",
        date: "Sep 2018 - Sep 2020",
        description:
          "Developed a chess game with AI capabilities. The AI component was partially implemented, but the game interface and core logic were completed.",
        skills: "Python, Game Development, AI",
      },
    ],
  },
  {
    category: "Electrical Engineering",
    icon: <FaMicrochip />,
    projects: [
      {
        title: "Transparent Solar Cells (Ongoing)",
        date: "2024 - Present",
        description:
          "Researching and developing transparent perovskite solar cells with a focus on energy efficiency and material optimization.",
        skills: "Nanotechnology, Renewable Energy, Semiconductor Physics",
      },
      {
        title: "Audio Amplifier",
        date: "December 2023",
        description:
          "Designed and developed an audio amplifier with volume control, LCD display for audio amplitude, and mono/stereo mode switching.",
        skills: "Circuit Design, Signal Processing, Embedded Systems",
      },
      {
        title: "FM Radio",
        date: "May 2023",
        description:
          "Built an FM radio with additional features such as temperature and proximity sensors, LCD display, and remote control functionality.",
        skills: "RF Design, Embedded Systems, PCB Design",
      },
      {
        title: "Capacitance Adapter for Multimeter",
        date: "May 2022",
        description:
          "Created an adapter to enhance multimeter capacitance measurement accuracy by converting high-frequency signals.",
        skills: "Analog Electronics, Measurement Systems",
      },
    ],
  },
  {
    category: "Other",
    icon: <FaTools />,
    projects: [
      {
        title: "Portfolio Website",
        date: "February 2024",
        description:
          "Designed and developed this portfolio website using Next.js and TailwindCSS, ensuring responsiveness and modern UI design.",
        skills: "Frontend Development, Next.js, TailwindCSS",
      },
      {
        title: "Home Automation System",
        date: "July 2023",
        description:
          "Developed an IoT-based home automation system using Raspberry Pi and ESP8266 for remote appliance control.",
        skills: "IoT, Embedded Systems, Home Automation",
      },
    ],
  },
];

export default function ProjectSection() {
  return (
    <section className={styles.projectSection} id="projects">
      <div className={styles.heading}>
        <FaProjectDiagram className={styles.headingIcon} />
        <h3>PROJECTS</h3>
      </div>

      {projectData.map((category, idx) => (
        <CategoryBlock key={idx} category={category} />
      ))}
    </section>
  );
}

function CategoryBlock({ category }) {
  const [expanded, setExpanded] = useState(false);
  const displayedProjects = expanded ? category.projects : category.projects.slice(0, 3);

  return (
    <div className={styles.categoryBlock}>
      <h4 className={styles.categoryTitle}>
        {category.icon} {category.category}
      </h4>

      {displayedProjects.map((project, idx) => (
        <ProjectCard key={idx} project={project} />
      ))}

      {category.projects.length > 3 && (
        <p className={styles.toggleCategory} onClick={() => setExpanded(!expanded)}>
          {expanded ? "Show Less" : "Show More"}
        </p>
      )}
    </div>
  );
}

function ProjectCard({ project }) {
  return (
    <div className={styles.card}>
      <div className={styles.cardTop}>
        <h5 className={styles.cardTitle}>{project.title}</h5>
        <p className={styles.date}>{project.date}</p>
      </div>
      <p className={styles.description}>{project.description}</p>
      <p className={styles.skills}><strong>Skills:</strong> {project.skills}</p>
    </div>
  );
}
