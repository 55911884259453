import React from "react";
import { 
  FaBriefcase, FaGraduationCap, FaProjectDiagram, FaCode, 
  FaServer, FaBrain, FaWrench, FaUsers 
} from "react-icons/fa";
import styles from "./OverviewSection.module.css";

// Overview data cards
const overviewData = [
  {
    id: 1,
    title: "Years of Professional Experience",
    value: "6",
    description: "Accumulated experience across software engineering, IT support, and machine learning automation.",
    icon: <FaBriefcase />,
  },
  {
    id: 2,
    title: "Years of Post-Secondary Education",
    value: "5",
    description: "Currently completing a BEng in Electronic Engineering at the University of Surrey.",
    icon: <FaGraduationCap />,
  },
  {
    id: 3,
    title: "Projects Completed",
    value: "15+",
    description: "Developed a range of programming, machine learning, and electrical engineering projects.",
    icon: <FaProjectDiagram />,
  },
  {
    id: 4,
    title: "Programming Languages",
    value: "10+",
    description: "Proficient in Python, C, C++, JavaScript, TypeScript, SQL, and more.",
    icon: <FaCode />,
  },
  {
    id: 5,
    title: "AWS & DevOps Certifications (Planned)",
    value: "10+",
    description: "Upcoming certifications include AWS Solutions Architect, Kubernetes, Terraform, and DevOps.",
    icon: <FaServer />,
  },
  {
    id: 6,
    title: "Machine Learning & AI Experience",
    value: "3+ Years",
    description: "Worked with YOLO, TensorRT, deep learning models, and AI-based trading bots.",
    icon: <FaBrain />,
  },
  {
    id: 7,
    title: "IT & Helpdesk Experience",
    value: "2+ Years",
    description: "Worked in IT support roles including Second Line Helpdesk at NHS & Kaplan.",
    icon: <FaWrench />,
  },
  {
    id: 8,
    title: "Leadership & Teamwork",
    value: "5+ Years",
    description: "Managed projects, teams, and collaborated on high-impact solutions.",
    icon: <FaUsers />,
  },
];

export default function OverviewSection() {
  return (
    <section className={styles.overviewSection} id="overview">
      <div className={styles.heading}>
        <FaProjectDiagram className={styles.headingIcon} />
        <h3>OVERVIEW</h3>
      </div>

      <div className={styles.gridContainer}>
        {overviewData.map((item) => (
          <div key={item.id} className={styles.card}>
            <div className={styles.icon}>{item.icon}</div>
            <h4 className={styles.cardTitle}>{item.value}</h4>
            <p className={styles.cardSubtitle}>{item.title}</p>
            <p className={styles.cardDescription}>{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
