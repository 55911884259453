"use client"; 
import React from 'react';
import { FaGamepad, FaFilm, FaDumbbell, FaPaintBrush, FaChartLine, FaFutbol } from 'react-icons/fa';
import styles from './HobbiesSection.module.css';

const hobbiesList = [
  { icon: <FaFilm />, title: "Content Creation", description: "Video editing (Premiere Pro), photo editing (Photoshop), and 3D modeling (Blender)." },
  { icon: <FaGamepad />, title: "Competitive Gaming", description: "Ranked Top 2000 out of 1.3 million players in the UK." },
  { icon: <FaDumbbell />, title: "Fitness", description: "Consistently training for health, strength, and endurance." },
  { icon: <FaPaintBrush />, title: "Creating Art", description: "Exploring digital and traditional artistic expressions." },
  { icon: <FaChartLine />, title: "Trading & Finances", description: "Funded trader with expertise in market analysis and risk management." },
  { icon: <FaFutbol />, title: "Sports Enthusiast", description: "Passionate about football and cricket." }
];

export default function HobbiesSection() {
  return (
    <section className={styles.hobbiesSection} id="hobbies">
      <div className={styles.heading}>
        <FaGamepad className={styles.headingIcon} />
        <h3>HOBBIES</h3>
      </div>

      <div className={styles.grid}>
        {hobbiesList.map((hobby, idx) => (
          <div key={idx} className={styles.hobbyCard}>
            <div className={styles.hobbyHeader}>
              {hobby.icon} 
              <h4 className={styles.hobbyTitle}>{hobby.title}</h4>
            </div>
            <p className={styles.hobbyDescription}>{hobby.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
