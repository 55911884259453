"use client";
import React, { useState } from "react";
import { FaChartLine } from "react-icons/fa";
import styles from "./FuturePlansSection.module.css";

// Grouped categories of future plans
const groupedPlans = [
  {
    category: "AWS Certifications",
    plans: [
      {
        title: "AWS Cloud Practitioner Foundation",
        provider: "Amazon Web Services",
        plannedDate: "Q1 2025",
        description: "Fundamental AWS cloud concepts, security, billing, and pricing models.",
      },
      {
        title: "AWS AI Practitioner Foundation",
        provider: "Amazon Web Services",
        plannedDate: "Q1 2025",
        description: "Introduction to AI and ML services on AWS, focusing on core AI tools.",
      },
      {
        title: "AWS Solutions Architect Associate",
        provider: "Amazon Web Services",
        plannedDate: "Q2 2025",
        description: "Design secure, scalable, and cost-efficient architectures on AWS.",
      },
      {
        title: "AWS SysOps Administrator – Associate",
        provider: "Amazon Web Services",
        plannedDate: "Q2 2025",
        description: "Deploy, manage, and operate workloads on AWS with automation.",
      },
      {
        title: "AWS Machine Learning Engineer Associate",
        provider: "Amazon Web Services",
        plannedDate: "Q3 2025",
        description: "Develop and deploy ML solutions using AWS AI and ML services.",
      },
      {
        title: "AWS Data Engineer Associate",
        provider: "Amazon Web Services",
        plannedDate: "Q3 2025",
        description: "Optimize AWS data pipelines, storage, and analytics solutions.",
      },
      {
        title: "AWS Machine Learning Specialty",
        provider: "Amazon Web Services",
        plannedDate: "Q1 2026",
        description: "Advanced machine learning techniques and deep learning on AWS.",
      },
      {
        title: "AWS DevOps Engineer Professional",
        provider: "Amazon Web Services",
        plannedDate: "Q2 2026",
        description: "Implement DevOps methodologies using AWS automation tools.",
      },
    ],
  },
  {
    category: "DevOps & Cloud Technologies",
    plans: [
      {
        title: "ITIL 4 Foundation",
        provider: "Axelos",
        plannedDate: "Q2 2026",
        description: "Introduction to IT service management and ITIL best practices.",
      },
      {
        title: "Docker Certified Associate (DCA)",
        provider: "Docker",
        plannedDate: "Q3 2026",
        description: "Containerization fundamentals, Docker security, and orchestration.",
      },
      {
        title: "Certified Kubernetes Administrator (CKA)",
        provider: "CNCF",
        plannedDate: "Q4 2026",
        description: "Hands-on Kubernetes administration, security, and troubleshooting.",
      },
      {
        title: "CloudBees Jenkins Engineer",
        provider: "CloudBees",
        plannedDate: "Q1 2027",
        description: "CI/CD pipeline automation and Jenkins optimization techniques.",
      },
      {
        title: "HashiCorp Certified: Terraform Associate",
        provider: "HashiCorp",
        plannedDate: "Q2 2027",
        description: "Infrastructure as Code (IaC) best practices using Terraform.",
      },
    ],
  },
  {
    category: "Data Science & Project Management",
    plans: [
      {
        title: "UiPath Certified RPA Associate",
        provider: "UiPath",
        plannedDate: "Q3 2027",
        description: "Robotic Process Automation (RPA) design and development skills.",
      },
      {
        title: "Splunk Core Certified User",
        provider: "Splunk",
        plannedDate: "Q4 2027",
        description: "Monitor, search, and analyze machine-generated data using Splunk.",
      },
      {
        title: "Data Science by BrainStation",
        provider: "BrainStation",
        plannedDate: "2028",
        description: "Practical application of data science techniques and ML models.",
      },
      {
        title: "Fundamentals of Quantitative Modeling",
        provider: "Coursera",
        plannedDate: "2028",
        description: "Statistical modeling and quantitative analysis for decision-making.",
      },
      {
        title: "Introduction to Data Engineering by IBM",
        provider: "IBM",
        plannedDate: "2028",
        description: "Core data engineering concepts, pipelines, and cloud data storage.",
      },
      {
        title: "Databricks Certified Data Engineer Associate",
        provider: "Databricks",
        plannedDate: "2028",
        description: "Big data processing, analytics, and Databricks platform proficiency.",
      },
      {
        title: "Professional Scrum Master I (PSM I)",
        provider: "Scrum.org",
        plannedDate: "2028",
        description: "Agile project management and Scrum framework certification.",
      },
      {
        title: "Project Management Essentials (PMI CAPM)",
        provider: "PMI",
        plannedDate: "2028",
        description: "Fundamentals of project management, frameworks, and methodologies.",
      },
    ],
  },
];


export default function FuturePlansSection() {
  return (
    <section className={styles.futurePlansSection} id="future-plans">
      <div className={styles.heading}>
        <FaChartLine className={styles.headingIcon} />
        <h3>FUTURE PLANS</h3>
      </div>

      {groupedPlans.map((category, idx) => (
        <CategoryBlock key={idx} category={category} />
      ))}
    </section>
  );
}

function CategoryBlock({ category }) {
  const [expanded, setExpanded] = useState(false);
  const displayedPlans = expanded ? category.plans : category.plans.slice(0, 3);

  return (
    <div className={styles.categoryBlock}>
      <h4 className={styles.categoryTitle}>{category.category}</h4>

      {displayedPlans.map((plan, idx) => (
        <PlanCard key={idx} plan={plan} />
      ))}

      {category.plans.length > 3 && (
        <p
          className={styles.toggleCategory}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "Show Less" : "Show More"}
        </p>
      )}
    </div>
  );
}

function PlanCard({ plan }) {
  return (
    <div className={styles.card}>
      <div className={styles.cardTop}>
        <div className={styles.iconBox}>
          <FaChartLine />
        </div>
        <h5 className={styles.cardTitle}>{plan.title}</h5>
        <p className={styles.provider}>{plan.provider}</p>
        <p className={styles.plannedDate}>{plan.plannedDate}</p>
      </div>

      {/* Description always visible */}
      <p className={styles.description}>{plan.description}</p>
    </div>
  );
}
