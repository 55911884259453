import React from 'react';
import './Hero.css';

function Hero() {
  return (
    <section className="hero-section">
      <div className="hero-image"></div>

      <div className="hero-overlay">
        <div className="hero-left">
          <h2>SAGAR DESAI</h2>
          <p className="hero-location">LONDON, ENG</p>
        </div>
        <div className="hero-right">
          <button className="contact-btn">CONTACT ME</button>
        </div>
      </div>
    </section>
  );
}

export default Hero;
