"use client";
import React from "react";
import { FaUserTie } from "react-icons/fa";
import styles from "./SummarySection.module.css";

export default function SummarySection() {
  return (
    <section className={styles.summarySection} id="summary">
      <div className={styles.heading}>
        <FaUserTie className={styles.headingIcon} />
        <h3>SUMMARY</h3>
      </div>

      <div className={styles.summaryContent}>
        <h4>
          👋 <strong>Software & Hardware Engineer | AI & DevOps Enthusiast | Problem-Solver</strong>
        </h4>
        <p>
          A <strong>highly skilled and versatile engineer</strong> with expertise in <strong>software development, 
          machine learning, DevOps, and electrical engineering</strong>. Passionate about automation, AI-driven solutions, 
          and scalable infrastructure. Proven track record in <strong>AI-based applications, cloud computing, and hardware innovations</strong>.
        </p>

        {/* Core Strengths */}
        <div className={styles.skillHighlights}>
          <h4>💡 Core Strengths</h4>
          <ul>
            <li><strong>Software & AI Development:</strong> Python, C, C++, JavaScript, Vue.js, machine learning, data science, algorithmic trading.</li>
            <li><strong>DevOps & Cloud:</strong> AWS (Cloud Practitioner, Solutions Architect, AI Practitioner), Docker, Kubernetes, Terraform, CI/CD automation.</li>
            <li><strong>Electrical Engineering:</strong> Circuit design, PCB layout, embedded systems (Arduino, Raspberry Pi), power electronics.</li>
            <li><strong>Process Automation & Data Engineering:</strong> Workflow optimization, big data processing, system automation.</li>
            <li><strong>Project Leadership:</strong> Agile methodologies, cross-functional team leadership.</li>
          </ul>
        </div>

        {/* Projects */}
        <div className={styles.notableProjects}>
          <h4>🚀 Notable Work</h4>
          <ul>
            <li>🔧 <strong>Process Automation:</strong> Created automated workflows to optimize company operations, reducing manual effort and improving efficiency.</li>
            <li>☀️ <strong>Transparent Solar Cell Research::</strong> Graphene-based perovskite solar energy innovation.</li>
            <li>🔧 <strong>Embedded Systems:</strong> Audio amplifiers, FM radios, and capacitance adapters.</li>
            <li>💰 <strong>AI-Powered Trading Bots:</strong> Developed algorithmic trading solutions leveraging ML for stocks, crypto, and CFDs.</li>
          </ul>
        </div>


        {/* Closing Statement */}
        <p className={styles.closingRemark}>
          With a <strong>problem-solving mindset</strong> and a commitment to <strong>continuous learning</strong>, 
          I strive to create innovative <strong>software and hardware solutions</strong> that enhance 
          efficiency, security, and automation across industries. 🚀
        </p>
      </div>
    </section>
  );
}
