import React from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Hero from './components/Hero';
import Summary from './components/SummarySection';
import OverviewSection from './components/OverviewSection';
import TimelineSection from './components/TimelineSection';
import FuturePlansSection from './components/FuturePlansSection';
import SkillsSection from './components/SkillsSection';
import LanguagesSection from './components/LanguagesSection';
import HobbiesSection from './components/HobbiesSection';
import ProjectsSection from './components/ProjectsSection';
import ScrollToTop from "./components/ScrollToTop";
import './App.css';

function App() {
  return (
    <div className="app-container">
      <Header />
      
      {/* This wrapper holds both the floating sidebar and the main content. */}
      <div className="content-wrapper">
        <Sidebar />
        
        {/* Main content (hero + summary) is offset so it’s not covered by the floating sidebar. */}
        <div className="main-content">
          <Hero />
          <Summary />
          <OverviewSection />
          <TimelineSection />
          <ProjectsSection />
          <FuturePlansSection />
          <SkillsSection />
          <LanguagesSection />
          <HobbiesSection />
        </div>
        <ScrollToTop />
      </div>
    </div>
  );
}

export default App;
