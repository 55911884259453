import React, { useState } from "react";
import { 
  FaBars, FaTimes, FaUser, FaChartPie, FaClock, FaProjectDiagram, 
  FaChartLine, FaGraduationCap, FaBriefcase, FaCogs, FaLanguage, 
  FaGamepad 
} from "react-icons/fa";
import "./Sidebar.css";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <aside className="floating-sidebar">
      {/* Hamburger button (visible on mobile) */}
      <div className="hamburger-btn" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>

      {/* Sidebar menu (desktop always visible, mobile toggles) */}
      <ul className={`sidebar-menu ${isOpen ? "open" : ""}`}>
        <li><a href="#summary"><FaUser className="menu-icon" /> SUMMARY</a></li>
        <li><a href="#overview"><FaChartPie className="menu-icon" /> OVERVIEW</a></li>
        <li><a href="#timeline"><FaClock className="menu-icon" /> TIMELINE</a></li>
        <li><a href="#projects"><FaProjectDiagram className="menu-icon" /> PROJECTS</a></li>
        <li><a href="#work-experience"><FaBriefcase className="menu-icon" /> WORK EXPERIENCE</a></li>
        <li><a href="#education"><FaGraduationCap className="menu-icon" /> EDUCATION</a></li>
        <li><a href="#future-plans"><FaChartLine className="menu-icon" /> FUTURE PLANS</a></li>
        <li><a href="#skills"><FaCogs className="menu-icon" /> SKILLS</a></li>
        <li><a href="#languages"><FaLanguage className="menu-icon" /> LANGUAGES</a></li>
        <li><a href="#hobbies"><FaGamepad className="menu-icon" /> HOBBIES</a></li>
      </ul>
    </aside>
  );
}

export default Sidebar;
