import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="site-header">
      <span className="logo">Sagar's Portfolio</span>
      <div className="header-right">
        {/* You can add top-right links or icons here if needed */}
      </div>
    </header>
  );
}

export default Header;
