"use client";
import React from 'react';
import { FaLanguage } from 'react-icons/fa';
import styles from './LanguagesSection.module.css';

// Updated language data with new proficiency levels
const languagesData = [
  { id: 1, name: "English", level: "Native", progress: 100 },
  { id: 2, name: "Gujarati", level: "Mother Tongue", progress: 100 },
  { id: 3, name: "Hindi", level: "Elementary", progress: 100 }
];

export default function LanguagesSection() {
  return (
    <section className={styles.languagesSection} id="languages">
      <div className={styles.heading}>
        <FaLanguage className={styles.headingIcon} />
        <h3>LANGUAGES</h3>
      </div>

      <div className={styles.grid}>
        {languagesData.map(lang => (
          <div key={lang.id} className={styles.languageCard}>
            {/* Progress bar is now above the text */}
            <div className={styles.progressBar}>
              <div
                className={styles.progressFill}
                style={{ width: `${lang.progress}%` }}
              ></div>
            </div>

            <h4 className={styles.langName}>{lang.name}</h4>
            <p className={styles.langLevel}>{lang.level}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

