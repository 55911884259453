import React, { useState, useEffect } from "react";
import { FaBriefcase, FaGraduationCap, FaClock } from "react-icons/fa";
import styles from "./TimelineSection.module.css";

const timelineData = [
  {
    id: 1,
    side: "left",
    type: "work-experience",
    title: "Retail Floor Manager",
    subtitle: "Nisa Local, Farnham, Surrey",
    dateRange: "09.2024 - 02.2025",
    details: [
      "Performed various store operations for closing procedures, product display management, and budget control.",
      "Managed store inventory by directing routine stock control procedures to maximise sales opportunities.",
      "Guaranteed delivery of first-class customer service, coaching team members to produce positive outcomes.",
      "Effectively assigned daily responsibilities to team members, ensuring smooth operations and optimal task completion across the store.",
    ],
  },
  {
    id: 2,
    side: "right",
    type: "work-experience",
    title: "Software Engineer - ML Automation",
    subtitle: "Vision4ce (Chess-Dynamics), Reading",
    dateRange: "09.2023 - 09.2024",
    details: [
      "Programming: Python (data scraping, automation, testing), Shell scripting (cluster scripts, data management).",
      "Data Engineering: Data scraping, database management, data indexing/triage with automated pipelines.",
      "Machine Learning: Implemented models (e.g., ResNet50, INT8 calibration), classification systems, cluster analysis.",
      "Deep Learning: Neural networks (ResNet50, YOLO), Darknet, TensorRT engine creation.",
      "DevOps: Apache Airflow (workflow management), CI DAG testing, automation scripts.",
      "Image Processing: Automated labelling, patch creation, and dataset organization by classification.",
      "Software Development: API creation, testing frameworks, Git version control.",
      "Other Skills: Docker (isolated environments), JSON/XML (configurations, labelling).",
    ],
  },
  {
    id: 3,
    side: "left",
    type: "work-experience",
    title: "Hospitality Worker",
    subtitle: "Off to Work, London",
    dateRange: "06.2022 - 09.2022",
    details: [
      "Problem-solving skills",
      "Attention to detail",
      "Technical knowledge",
      "Teamwork/team management",
      "Managed over multiple clients calls per day.",
      "Acquired health and safety qualifications to ensure safety in the working environment",
    ],
  },
  {
    id: 4,
    side: "right",
    type: "education",
    title:
      "Bachelor of Engineering - Electronic Engineering With Foundation And Placement Training Year",
    subtitle: "University of Surrey, Guildford",
    dateRange: "09.2020 - Current",
    details: [
      "On track to achieve a First Honers",
      "Member of SamSoc, Volleyball, SVGS, ISA, DesiSoc",
    ],
  },
  {
    id: 5,
    side: "left",
    type: "education",
    title: "A-Levels",
    subtitle: "Ernest Bevin College, London",
    dateRange: "09.2018 - 07.2020",
    details: [
      "Maths (B), Physics (C), Computer Science (C)",
      "Member of Cricket",
    ],
  },
  {
    id: 6,
    side: "right",
    type: "work-experience",
    title: "Second Line Help Desk",
    subtitle: "NHS, London",
    dateRange: "11.2018 - 11.2018",
    details: [
      "Technical troubleshooting",
      "IT infrastructure",
      "Customer service",
      "Documentation and reporting",
      "Participated in fire training, CPR training, and first aid to ensure safety in workplace.",
      "Managed multiple clients calls per day",
    ],
  },
  {
    id: 7,
    side: "left",
    type: "work-experience",
    title: "Help Desk",
    subtitle: "Kaplan, London",
    dateRange: "10.2018 - 10.2018",
    details: [
      "Technical troubleshooting",
      "Customer service",
      "IT infrastructure",
      "Time management",
      "Gained insight into IT support system and cyber security.",
      "Shadowed experts to learn different ways of solving IT-related issues",
      "Managed multiple clients calls per day",
    ],
  },
  {
    id: 8,
    side: "right",
    type: "education",
    title: "GCSE",
    subtitle: "Ernest Bevin College, London",
    dateRange: "09.2013 - 07.2018",
    details: [
      "Maths (7), English Language (4), English Literature (5), Combined Science (6,5),Geography (4), Business Studies (D)",
      "Member of Cricket, CCF, Football",
    ],
  },
];

export default function TimelineSection() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className={styles.timelineSection} id="timeline">
      <div className={styles.heading}>
        <FaClock className={styles.headingIcon} />
        <h3>TIMELINE</h3>
      </div>

      {isMobile ? <MobileTimeline /> : <DesktopTimeline />}
    </section>
  );
}

function DesktopTimeline() {
  return (
    <div className={styles.timelineContainer}>
      {timelineData.map((item) => (
        <div key={item.id} className={styles.row}>
          {/* left column => if side=left, show the card */}
          <div className={styles.leftCol}>
            {item.side === "left" && <TimelineCard item={item} />}
          </div>

          {/* center column => line + dot in the middle on desktop */}
          <div className={styles.centerCol}>
            <div className={styles.dot} />
          </div>

          {/* right column => if side=right, show the card */}
          <div className={styles.rightCol}>
            {item.side === "right" && <TimelineCard item={item} />}
          </div>
        </div>
      ))}
    </div>
  );
}

function MobileTimeline() {
  return (
    <div className={styles.timelineContainer}>
      {timelineData.map((item) => (
        <div key={item.id} className={styles.row}>
          {/* center column => line + dot in the middle on desktop */}
          <div className={styles.centerCol}>
            <div className={styles.dot} />
          </div>

          {/* right column => if side=right, show the card */}
          <div className={styles.rightCol}>
            {(item.side === "right" && <TimelineCard item={item} />) ||
              (item.side === "left" && <TimelineCard item={item} />)}
          </div>
        </div>
      ))}
    </div>
  );
}

function TimelineCard({ item }) {
  const { type, title, subtitle, dateRange } = item;
  let IconComp = FaBriefcase;
  if (type === "education") {
    IconComp = FaGraduationCap;
  }

  const [expanded, setExpanded] = useState(false);

  const toggleDesc = () => setExpanded(!expanded);

  return (
    <div className={styles.cardLink}>
      <div className={styles.card}>
        <div className={styles.cardIcon}>
          <IconComp />
        </div>
        <h4 className={styles.cardTitle}>{title}</h4>
        <p className={styles.cardSubtitle}>{subtitle}</p>
        <p className={styles.cardDate}>{dateRange}</p>
        {/* Show/hide details */}
        {expanded && (
          <ul className={styles.detailsList}>
            {item.details.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        )}

        {/* Toggle link */}
        <p className={styles.toggleDesc} onClick={toggleDesc}>
          {expanded ? "Hide Description" : "Show Description"}
        </p>
      </div>
    </div>
  );
}
