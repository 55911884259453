import React from "react";
import { 
  FaCode, FaServer, FaMicrochip, FaProjectDiagram, 
  FaBrain, FaWrench, FaUsers, FaToolbox 
} from "react-icons/fa";
import styles from "./SkillsSection.module.css";

// Categorized skills data
const skillsData = [
  {
    category: "Programming & Software Development",
    icon: <FaCode />,
    skills: [
      "Python", "C", "C++", "JavaScript", "TypeScript", "HTML", "CSS", "PineScript",
      "Vue.js", "React", "D3.js",
      "Git, GitHub, GitLab",
      "Shell Scripting, Bash, PowerShell",
      "REST APIs, JSON, XML",
      "Web Scraping (BeautifulSoup, Selenium, Scrapy)",
      "Apache Airflow",
      "SQL, NoSQL, MongoDB, PostgreSQL"
    ]
  },
  {
    category: "Machine Learning & Data Science",
    icon: <FaBrain />,
    skills: [
      "Supervised & Unsupervised Learning",
      "Neural Networks, ResNet50, YOLO, TensorRT",
      "Data Preprocessing & Indexing",
      "Data Visualization (D3.js, Matplotlib, Grafana)",
      "AWS Machine Learning Services",
      "Databricks",
      "Algorithmic Trading & AI-based Trading Strategies"
    ]
  },
  {
    category: "DevOps & Cloud Technologies",
    icon: <FaServer />,
    skills: [
      "AWS (Cloud Practitioner, Solutions Architect, AI Practitioner)",
      "Docker, Kubernetes",
      "CI/CD (Jenkins, GitHub Actions)",
      "Terraform, HashiCorp",
      "Prometheus, Grafana, Splunk",
      "Cloud Security & Compliance"
    ]
  },
  {
    category: "Electrical Engineering & Hardware",
    icon: <FaMicrochip />,
    skills: [
      "Circuit Design & Simulation",
      "Embedded Systems (Arduino, Raspberry Pi)",
      "Sensor Integration (Temperature, Proximity, Infrared, LCDs)",
      "Power Electronics (Capacitors, Amplifiers, Signal Processing)",
      "PCB Design & Layout"
    ]
  },
  {
    category: "IT & Tech Support",
    icon: <FaWrench />,
    skills: [
      "IT Troubleshooting & Diagnostics",
      "Help Desk Support & Customer Service",
      "Network & System Administration",
      "Technical Documentation & Knowledge Base"
    ]
  },
  {
    category: "Project Management & Leadership",
    icon: <FaProjectDiagram />,
    skills: [
      "Agile & Scrum Methodologies",
      "Team Management & Leadership",
      "Time & Resource Management",
      "Client Relations & Stakeholder Communication"
    ]
  },
  {
    category: "Soft Skills",
    icon: <FaUsers />,
    skills: [
      "Critical Thinking & Problem Solving",
      "Attention to Detail",
      "Flexibility & Adaptability",
      "Effective Communication",
      "Creativity & Innovation"
    ]
  }
];

export default function SkillsSection() {
  return (
    <section className={styles.skillsSection} id="skills">
      <div className={styles.heading}>
        <FaToolbox className={styles.headingIcon} />
        <h3>SKILLS</h3>
      </div>

      <div className={styles.gridContainer}>
        {skillsData.map((category, idx) => (
          <div key={idx} className={styles.skillCard}>
            <h4 className={styles.categoryTitle}>
              {category.icon} {category.category}
            </h4>
            <ul className={styles.skillList}>
              {category.skills.map((skill, skillIdx) => (
                <li key={skillIdx} className={styles.skillItem}>{skill}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
}
